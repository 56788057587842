

.rti--container {
  max-width: auto;

}
.rti--tag{
  background-color: #3b82f6;

  margin-right: 6px;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 14px;
  color: wheat;
  

}
.rti--tag-remove{
  margin: 10px;
}